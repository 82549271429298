import addDays from 'date-fns/add_days';
import subDays from 'date-fns/sub_days';
import subMonths from 'date-fns/sub_months';
import isEmpty from 'lodash/isEmpty';
import tail from 'lodash/tail';
import get from 'lodash/get';
import head from 'lodash/head';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';

import env from '../../env';
import GeneralUtils from '../../Helpers/GeneralUtils';
import { currencyCode } from '../../Helpers/Geo/data';
import searchSetup from './algolia-setup';
import { get as apiGet } from '../../api';
import store, { history } from '../store';
import { encodeSlug } from '../../Helpers/Slug';
import mostGiftedSlugs from '../../containers/AlgoliaSections/most-gifted-slugs';
import fullPriceGameExclusions from '../../components/Banner/full-price-game-exclusions';

const today = Math.round((new Date()).getTime() / 1000);
const plusTwoDays = Math.round(addDays(new Date(), 2).getTime() / 1000);
const minusSevenDays = Math.round(subDays(new Date(), 7).getTime() / 1000);
const minusOneMonth = Math.round(subMonths(new Date(), 1).getTime() / 1000);
const minusTwoMonths = Math.round(subMonths(new Date(), 2).getTime() / 1000);
const minusThreeMonths = Math.round(subMonths(new Date(), 3).getTime() / 1000);
const globalAttributesToRetrieve = [
  'product_id',
  'name',
  'slug',
  'type',
  'discount_percent',
  'cover',
  'cover_type',
  'cover_alt_text',
  'price',
  'fullPrice',
  'position',
  'presale',
  'hide_discount',
  'display_type',
  'giveaway',
  'tiered',
  'best_ever',
  'flash_sale',
  'operating_systems',
  'drm',
  'available_valid_from',
  'available_valid_until',
  'collections',
  'no_release_date',
  'on_sale',
  'free_to_play',
  'release_date',
  'bundle_covers',
  'mystery',
  'game_total',
  'dlc_total',
  'hide_price',
  'screenshots',
  'video_clip_poster',
  'video_clip_files',
  'age_ratings',
  'pay_what_you_want',
  'video',
  'hideDLC',
  'pnm_saving',
  'discount_slug',
  'metacritic_score',
  'preorder_playable_from',
  'star_deal',
  'rating_score',
  'total_ratings',
  'is_srp_bundle',
  'portrait_cover',
];

const isAltRank = false;

export const indexAlgoliaName = env.algolia.indexName;
export const topSellerIndex = () => (isAltRank ? `${indexAlgoliaName}_alt_rank` : indexAlgoliaName);

const apiAlgoliaSplitTest = path => `${path}${path.includes('?') ? '&' : '?'}altRank=${isAltRank}`;


// Make sure we don't send NaN to algolia
const sanitizePageNumber = input => Number(input) || 0;

const createSlugsToExcludeFilter = (slugs) => {
  const headSlugString = head(slugs);
  const tailingSlugs = tail(slugs);
  let tailingSlugsString = '';

  if (!isEmpty(tailingSlugs)) {
    tailingSlugsString = tailingSlugs.map(slug => `AND NOT slug:"${slug}"`).join(' ');
  }

  return `(NOT slug:"${headSlugString}" ${tailingSlugsString})`;
};

const createNamesToExcludeFilter = (names) => {
  const headNameString = head(names);
  const tailingNames = tail(names);
  let tailingNamesString = '';

  if (!isEmpty(tailingNames)) {
    tailingNamesString = tailingNames.map(name => `AND NOT name:"${name}"`).join(' ');
  }

  return `(NOT name:"${headNameString}" ${tailingNamesString})`;
};

// factFilters helper
// ["category:Book", "author:John Doe"] translates as category:Book AND author:"John Doe".
// [["category:Book", "category:Movie"]] translates as category:Book OR category:Movie.
// [["category:Book", "category:Movie"], "author:John Doe"] translates as (category:Book OR category:Movie) AND author:"John Doe".

export async function fetchGamesNotOnSale(currentPage) {
  const indexNotOnSale = await searchSetup(topSellerIndex());
  return indexNotOnSale.search('', {
    page: sanitizePageNumber(currentPage),
    hitsPerPage: 36,
    filters: `NOT discount_percent > 1 AND type:game AND presale != 1 AND ${createSlugsToExcludeFilter(fullPriceGameExclusions)}`,
  }).then(hits => hits);
}

export async function fetchLatestDeals(currentPage) {
  const indexLatestDeals = await searchSetup(`${indexAlgoliaName}_latest_deals`);
  return indexLatestDeals.search('', {
    filters: `discount_percent > 0 AND available_valid_from > ${minusSevenDays} AND system_discount = 0`,
    page: sanitizePageNumber(currentPage),
    hitsPerPage: 36,
  }).then((initialLatestDeals) => {
    const hits = get(initialLatestDeals, 'hits', []);
    if (hits.length < 12) {
      return indexLatestDeals.search('', {
        filters: `discount_percent > 0 AND available_valid_from > ${minusSevenDays}`,
        page: sanitizePageNumber(currentPage),
        hitsPerPage: 36,
      }).then(latestDeals => latestDeals);
    }
    return initialLatestDeals;
  });
}

export async function fetchLatestDealsSection(hitsPerPage) {
  const indexLatestDeals = await searchSetup((`${indexAlgoliaName}_latest_deals`));
  return indexLatestDeals.search('', {
    filters: `discount_percent > 0 AND available_valid_from > ${minusSevenDays} AND release_date < ${minusOneMonth} AND position > 11`,
    hitsPerPage: hitsPerPage || 4,
  }).then(latestDealsSection => latestDealsSection);
}

export async function fetchTrendingDeals(page, hitsPerPage) {
  const indexLatestDeals = await searchSetup(`${indexAlgoliaName}_best_price_rank`);
  return indexLatestDeals.search('', {
    page: sanitizePageNumber(page),
    hitsPerPage: hitsPerPage || 36,
    filters: 'discount_percent > 0',
  }).then(hits => hits);
}

export async function fetchTrendingDealsCarousel(hitsPerPage = 12, slugsToExclude) {
  const index = await searchSetup(`${indexAlgoliaName}_best_price_rank`);
  return index.search('', {
    filters: `${createSlugsToExcludeFilter(slugsToExclude)} AND mystery_homepage != 1 AND NOT display_type:bundle AND NOT display_type:book-bundle AND discount_percent > 0`,
    hitsPerPage: hitsPerPage || 12,
  }).then(hits => hits.hits);
}

export async function fetchExtraCardPanelHits(hitsPerPage, slugsToExclude, fantasyVerse) {
  let index = await searchSetup(`${indexAlgoliaName}_best_price_rank`);
  if (fantasyVerse) index = await searchSetup(topSellerIndex());

  if (fantasyVerse) {
    return index.search('', {
      filters: `${createSlugsToExcludeFilter(slugsToExclude)}`,
      facetFilters: ['collectionsSlug:fantasyverse'],
      hitsPerPage: hitsPerPage || 5,
    }).then(hits => hits.hits);
  }

  return index.search('', {
    filters: `${createSlugsToExcludeFilter(slugsToExclude)}`,
    hitsPerPage: hitsPerPage || 5,
  }).then(hits => hits.hits);
}

export async function fetchLatestDealsCarousel(hitsToDisplay = 12, slugsToExclude) {
  const indexLatestDeals = await searchSetup(`${indexAlgoliaName}_latest_deals`);
  return indexLatestDeals.search('', {
    filters: `${createSlugsToExcludeFilter(slugsToExclude)} AND discount_percent > 0 AND available_valid_from > ${minusSevenDays} AND release_date < ${minusOneMonth} AND mystery_homepage != 1 AND NOT display_type:bundle AND NOT display_type:book-bundle `,
    hitsPerPage: hitsToDisplay,
  }).then(latestDealsSection => latestDealsSection.hits);
}


export async function fetchDealsUnderCarousel(hitsPerPage, slugsToExclude, price) {
  const index = await searchSetup(topSellerIndex());

  return index.search('', {
    hitsPerPage: hitsPerPage || 12,
    filters: `${createSlugsToExcludeFilter(slugsToExclude)} AND discount_percent > 0 AND price.${currencyCode} < ${price} AND mystery_homepage != 1 AND NOT display_type:bundle AND NOT display_type:book-bundle  `,
  }).then(results => results.hits);
}

export async function fetchBundleCarousel(hitsPerPage, slugsToExclude) {
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  const indexBundles = await searchSetup(topSellerIndex());
  return indexBundles.search('', {
    // eslint-disable-next-line max-len
    facetFilters: 'display_type:-book-bundle',
    filters: `${createSlugsToExcludeFilter(slugsToExclude)} AND display_type:bundle AND NOT display_type:software-bundle AND mystery_homepage != 1 AND NOT drm:switch AND NOT drm:threeds AND NOT display_type:elearning-bundle AND NOT display_type:audio-bundle`,
    hitsPerPage: hitsPerPage || 12,
    attributesToRetrieve,
  }).then(bundleSection => bundleSection.hits);
}

const createTypeFilters = (filters) => { // eslint-disable-line no-unused-vars
  const filterObj = {
    games: 'display_type:game',
    bundles: 'display_type:software-bundle OR display_type:bundle OR display_type:book-bundle OR display_type:elearning-bundle OR display_type:elearning OR display_type:comic-bundle OR display_type:audio-bundle',
    dlc: 'display_type:dlc',
  };

  const filtersArray = Object.keys(filters).filter(key => filters[key]);
  let headFilterString = '';

  const headFilter = filterObj[filtersArray[0]];

  if (headFilter) {
    headFilterString = `AND ${headFilter}`;
  }

  let tailingFiltersSting = '';

  const tailingFilters = tail(filtersArray);

  if (!isEmpty(tailingFilters)) {
    tailingFiltersSting = tailingFilters.map(filter => `OR ${filterObj[filter]}`).join(' ');
  }

  return `${headFilterString} ${tailingFiltersSting}`;
};

export async function fetchEndingSoon(currentPage, hitsPerPage) {
  const indexEndingSoon = await searchSetup(`${indexAlgoliaName}_ending_soon`);
  return indexEndingSoon.search('', {
    filters: `discount_percent > 0 AND available_valid_until < ${plusTwoDays} AND system_discount = 0`,
    page: sanitizePageNumber(currentPage),
    hitsPerPage: hitsPerPage || 36,
  }).then((initialEndingSoon) => {
    const hits = get(initialEndingSoon, 'hits', []);
    if (hits.length < 12) {
      return indexEndingSoon.search('', {
        filters: `discount_percent > 0 AND available_valid_until < ${plusTwoDays}`,
        page: sanitizePageNumber(currentPage),
        hitsPerPage: hitsPerPage || 36,
      }).then(endingSoon => endingSoon);
    }
    return initialEndingSoon;
  });
}

export async function fetchEndingSoonSection() {
  const indexEndingSoon = await searchSetup(topSellerIndex());
  return indexEndingSoon.search('', {
    filters: `discount_percent > 0 AND available_valid_until < ${plusTwoDays}`,
    hitsPerPage: 15,
  });
}

export async function fetchNewReleases(hitsPerPage, page = 0) {
  const indexNewReleases = await searchSetup(topSellerIndex());
  return indexNewReleases.search('', {
    hitsPerPage: hitsPerPage || 36,
    filters: `release_date > ${minusThreeMonths} AND release_date < ${today} AND (display_type:game OR display_type:dlc)`,
    page,
  });
}

// filters: `release_date > ${minusTwoMonths} AND release_date < ${today} AND (display_type:game OR display_type:dlc)`,

export async function fetchNewReleasesSection() {
  const indexNewReleases = await searchSetup(topSellerIndex());
  return indexNewReleases.search('', {
    filters: `release_date > ${minusTwoMonths} AND release_date < ${today} AND (display_type:game OR display_type:dlc)`,
    hitsPerPage: 15,
  }).then(hits => hits.hits);
}

export async function fetchMetacriticNewReleases() {
  const indexNewReleases = await searchSetup(`${indexAlgoliaName}_metacritic`);
  return indexNewReleases.search('', {
    filters: `release_date > ${minusThreeMonths} AND release_date < ${today} AND metacritic_score > 0 AND presale != 1`,
    hitsPerPage: 30,
  }).then(hits => hits.hits);
}

// filters: `release_date > ${minusTwoMonths} AND release_date < ${today} AND metacritic_score > 0`,

export async function fetchMostWantedNewReleases() {
  const indexMostWanted = await searchSetup(`${indexAlgoliaName}_most_wanted`);
  return indexMostWanted.search('', {
    filters: `release_date > ${minusThreeMonths} AND release_date < ${today} AND type:game OR type:dlc`,
    hitsPerPage: 10,
  }).then(hits => hits.hits);
}

export async function fetchVrSection() {
  const indexNewReleases = await searchSetup(topSellerIndex());
  return indexNewReleases.search('', {
    facetFilters: [['features:VR Support', 'slug:platinum-vr-collection-build-your-own-bundle']],
    hitsPerPage: 15,
  });
}

export async function fetchSteamDeckGamesSection() {
  const indexNewReleases = await searchSetup(topSellerIndex());
  return indexNewReleases.search('', {
    facetFilters: [['steam_deck_support:verified', 'steam_deck_support:playable']],
    hitsPerPage: 15,
  });
}

export async function fetchBundles(hitsPerPage = 100) {
  const indexBundles = await searchSetup(topSellerIndex());
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexBundles.search('', {
    filters: 'display_type:bundle AND NOT display_type:software-bundle',
    hitsPerPage,
    attributesToRetrieve,
  }).then(bundles => bundles);
}

export async function fetchSoftwareBundleSection() {
  const indexBundles = await searchSetup(topSellerIndex());
  return indexBundles.search('', {
    filters: 'display_type:software-bundle',
    hitsPerPage: 4,
  }).then(softwareBundleSection => softwareBundleSection);
}

export async function fetchBundleFranchiseSection() {
  const indexBundles = await searchSetup(topSellerIndex());
  return indexBundles.search('', {
    // eslint-disable-next-line max-len
    facetFilters: 'franchises:Franchise Bundles',
    filters: 'display_type:bundle',
    hitsPerPage: 4,
  }).then(bundleFranchiseSection => bundleFranchiseSection);
}

export async function fetchComingSoonPage(index) {
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  const client = await searchSetup();

  const queries = [
    {
      indexName: index ? `${indexAlgoliaName}_${index}` : topSellerIndex(),
      params: {
        filters: `release_date > ${today} AND NOT type:pick-and-mix AND presale != 1`,
        hitsPerPage: 100,
        attributesToRetrieve,
      },
    },
    {
      indexName: index ? `${indexAlgoliaName}_${index}` : `${indexAlgoliaName}_most_wanted`,
      params: {
        filters: `release_date > ${today} AND NOT type:pick-and-mix AND presale = 1`,
        hitsPerPage: 100,
        attributesToRetrieve,
      },
    },
  ];

  const results = await client.search(queries);

  return {
    available: get(results, 'results[0].hits', []),
    presale: get(results, 'results[1].hits', []),
  };
}

export async function fetchReleased(currentPage) {
  const indexBundles = await searchSetup(topSellerIndex());
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexBundles.search('', {
    filters: `initial_sale > ${minusOneMonth} AND initial_sale > 0 AND type:game OR type:dlc`,
    hitsPerPage: 60,
    page: sanitizePageNumber(currentPage),
    attributesToRetrieve,
  }).then(released => released);
}

export async function fetchComingSoonSection() {
  const indexBundles = await searchSetup(topSellerIndex());
  return indexBundles.search('', {
    filters: `release_date > ${today} AND position > 23 AND NOT type:pick-and-mix`,
    hitsPerPage: 15,
  }).then(comingSoonSection => comingSoonSection.hits);
}

export async function fetchFlashDeals(currentPage) {
  const indexFlashDeals = await searchSetup(topSellerIndex());
  return indexFlashDeals.search('', {
    page: sanitizePageNumber(currentPage),
    hitsPerPage: 36,
    filters: 'flash_sale = 1 AND discount_percent > 0',
  }).then(hits => hits);
}

export async function fetchFlashDealsSection() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/flash-sale'));
  return data;
}

export async function fetchAllTopSellersSection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    facetFilters: ['publishers:-ea', 'publishers:-bethesda-softworks'],
    filters: 'mystery_homepage != 1 AND NOT drm:switch AND NOT drm:threeds AND NOT display_type:software-bundle AND NOT display_type:bundle AND NOT display_type:elearning-bundle AND NOT display_type:elearning AND NOT display_type:book-bundle AND NOT display_type:audio-bundle',
    hitsPerPage: hitsPerPage || 15,
  }).then(topSellers => topSellers.hits);
}

export async function fetchTopSellersSection(hitsPerPage, seperateBundles) {
  const reduxStore = store.getState();
  const flashSale = get(reduxStore, 'flashSale');
  let algoliaFilters = `mystery_homepage != 1 AND NOT drm:switch AND NOT drm:threeds AND NOT display_type:software-bundle ${seperateBundles ? 'AND NOT display_type:bundle' : ''} AND NOT display_type:elearning-bundle AND NOT display_type:elearning AND NOT display_type:audio-bundle AND NOT display_type:audio`;
  // Only exclude flash sale products if promotion is running
  if (!isEmpty(flashSale)) {
    algoliaFilters = `flash_sale != 1 AND mystery_homepage != 1 AND NOT drm:switch AND NOT drm:threeds AND NOT display_type:software-bundle ${seperateBundles ? 'AND NOT display_type:bundle' : ''} AND NOT display_type:elearning-bundle AND NOT display_type:elearning AND NOT display_type:audio-bundle AND NOT display_type:audio`;
  }

  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    facetFilters: [['display_type:-book-bundle']],
    filters: algoliaFilters,
    hitsPerPage: hitsPerPage || 11,
  }).then(topSellers => topSellers.hits);
}

export async function fetchTopSellersSectionWithMystery(hitsPerPage, seperateBundles) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    facetFilters: 'display_type:-book-bundle',
    filters: `NOT drm:switch AND NOT drm:threeds ${seperateBundles ? 'AND NOT display_type:bundle' : ''}`,
    hitsPerPage: hitsPerPage || 11,
  }).then(topSellers => topSellers.hits);
}

export async function fetchMysterySection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    facetFilters: 'display_type:bundle',
    filters: 'mystery = 1 AND mystery_homepage != 1 AND mystery_upsell != 1',
    hitsPerPage: hitsPerPage || 4,
  }).then(topSellers => topSellers.hits);
}

export async function fetchTopSellersGamesSection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    hitsPerPage: hitsPerPage || 15,
    filters: 'flash_sale != 1 AND mystery_homepage != 1 AND type:game OR type:dlc',
  }).then(topSellersGames => topSellersGames.hits);
}

export async function fetchMacGamesSection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    hitsPerPage: hitsPerPage || 15,
    filters: 'operating_systems:mac AND flash_sale != 1 AND mystery_homepage != 1 AND display_type:game OR display_type:dlc',
  }).then(topSellersGames => topSellersGames.hits);
}

export async function fetchLinuxGamesSection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    hitsPerPage: hitsPerPage || 15,
    filters: 'operating_systems:linux AND flash_sale != 1 AND mystery_homepage != 1 AND display_type:game OR display_type:dlc',
  }).then(topSellersGames => topSellersGames.hits);
}

export async function fetchFreeToPlay(currentPage) {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    filters: 'free_to_play = 1 AND type:game OR type:dlc',
    page: sanitizePageNumber(currentPage),
    hitsPerPage: 36,
  });
}

export async function fetchPublisher(publisher, indexName, page = 0) {
  const index = await searchSetup(indexName ? `${indexAlgoliaName}_${indexName}` : topSellerIndex());
  return index.search('', {
    facetFilters: `publishers:${publisher}`,
    hitsPerPage: 100,
    page,
  });
}

export async function fetchFreeToPlaySection(hitsPerPage) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  return indexTopSellers.search('', {
    hitsPerPage: hitsPerPage || 15,
    filters: 'free_to_play = 1 AND type:game OR type:dlc',
  }).then(topSellersGames => topSellersGames.hits);
}

export async function fetchSingleFlashDealSection() {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexTopSellers.search('', {
    hitsPerPage: 1,
    filters: 'flash_sale = 1 AND discount_percent > 0',
    attributesToRetrieve,
  }).then(singleFlashDeal => singleFlashDeal.hits);
}

export async function fetchTopSellers(type, count) {
  const { locale } = window;
  const { data } = await apiGet([], apiAlgoliaSplitTest(`/algolia/top-sellers${type ? `/${type}` : ''}?lang=${locale}${count ? `&count=${count}` : ''}`));
  return data;
}

export async function fetchTopSellersExclude(hitsPerPage, slugsToExclude) {
  const indexName = topSellerIndex();
  const indexTopSellers = await searchSetup(indexName);
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexTopSellers.search('', {
    hitsPerPage: hitsPerPage || 11,
    facets: ['display_type'],
    page: 0,
    attributesToRetrieve,
    filters: createSlugsToExcludeFilter(slugsToExclude),
  }).then(results => results.hits);
}

export async function fetchSearchHits(facet, value, dashValue, page) {
  const index = await searchSetup(topSellerIndex());
  return index.search('', {
    hitsPerPage: 36,
    page,
    facetFilters: [[`${facet}:${value}`, `${facet}:${dashValue}`]],
    filters: 'NOT display_type:bundle',
  }).then(searchHits => searchHits);
}

export async function query(searchString, hitsPerPage, slugsToExclude) {
  const index = await searchSetup(topSellerIndex());
  return index.search(searchString || '', {
    filters: createSlugsToExcludeFilter(slugsToExclude),
    hitsPerPage: hitsPerPage || 3,
  }).then(searchHits => searchHits.hits);
}

export async function fetchStaticQuery({ facet, value, callPage, facets = [], type, index }) {
  const searchIndex = index ? `${indexAlgoliaName}_${index}` : topSellerIndex();
  const indexNewReleases = await searchSetup(searchIndex);
  const attributesToRetrieve = globalAttributesToRetrieve.concat([facet, ...facets]);

  const searchQuery = {
    facetFilters: `${facet}Slug:${value}`,
    page: sanitizePageNumber(callPage),
    facets,
    filters: '',
    hitsPerPage: 36,
    attributesToRetrieve,
  };

  if (index === 'price_asc') searchQuery.filters += 'presale != 1';
  if (type) searchQuery.filters += `${searchQuery.filters ? ' AND ' : ''}type: ${type}`;

  return indexNewReleases.search('', searchQuery);
}

export async function fetchXboxGames(callPage, hitsPerPage, filterType, sort) {
  const index = await searchSetup(sort ? `${indexAlgoliaName}_${sort}` : topSellerIndex());

  let displayTypeFilter = 'NOT display_type:bundle';
  if (filterType === 'game') displayTypeFilter = 'display_type:game';
  if (filterType === 'dlc') displayTypeFilter = 'display_type:dlc';

  return index.search('', {
    facets: ['display_type'],
    filters: `drm:xbox AND NOT display_type:gift-card AND free_to_play != 1 AND ${displayTypeFilter}`,
    page: sanitizePageNumber(callPage),
    hitsPerPage: hitsPerPage || 36,
  });
}

export async function fetchGamesUnder(priceFilter, callPage, hitsPerPage, sort) {
  const index = await searchSetup(sort ? `${indexAlgoliaName}_${sort}` : topSellerIndex());

  return index.search('', {
    facets: ['display_type'],
    filters: `${priceFilter} AND presale != 1 AND display_type:game`,
    page: sanitizePageNumber(callPage),
    hitsPerPage: 36,
  });
}

export async function fetchPlatformMac(callPage, hitsPerPage, filterType, sort) {
  const index = await searchSetup(sort ? `${indexAlgoliaName}_${sort}` : topSellerIndex());

  let displayTypeFilter = 'NOT display_type:bundle';
  if (filterType === 'game') displayTypeFilter = 'display_type:game';
  if (filterType === 'dlc') displayTypeFilter = 'display_type:dlc';

  return index.search('', {
    facets: ['display_type'],
    filters: `operating_systems:mac AND presale != 1 AND ${displayTypeFilter}`,
    page: sanitizePageNumber(callPage),
    hitsPerPage: 36,
  });
}

export async function fetchPlatformLinux(callPage, hitsPerPage, filterType, sort) {
  const index = await searchSetup(sort ? `${indexAlgoliaName}_${sort}` : topSellerIndex());

  let displayTypeFilter = 'NOT display_type:bundle';
  if (filterType === 'game') displayTypeFilter = 'display_type:game';
  if (filterType === 'dlc') displayTypeFilter = 'display_type:dlc';

  return index.search('', {
    facets: ['display_type'],
    filters: `operating_systems:linux AND presale != 1 AND ${displayTypeFilter}`,
    page: sanitizePageNumber(callPage),
    hitsPerPage: 36,
  });
}

export async function fetchFacets(facet) {
  const indexTopSellers = await searchSetup(indexAlgoliaName);
  return indexTopSellers.search('', {
    attributesToRetrieve: [facet],
    facets: [facet],
    sortFacetValuesBy: 'count',
    maxValuesPerFacet: 1000,
    facetingAfterDistinct: true,
    page: 0,
  });
}

export async function fetchStaffPicks(hitsPerPage) {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    facetFilters: 'collections:Staff Picks',
    hitsPerPage: hitsPerPage || 6,
  });
}

export async function fetchStaffPicksSection(hitsPerPage) {
  const { locale } = window;
  const indexTopSellers = await searchSetup(topSellerIndex());
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexTopSellers.search('', {
    facetFilters: 'collections:Staff Picks',
    hitsPerPage: hitsPerPage || 15,
    attributesToRetrieve,
  });
}

export async function fetchPublisherSection(publisher) {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    facetFilters: `publishers:${publisher}`,
    hitsPerPage: 15,
  });
}

export async function fetchCategorySection(category) {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    facetFilters: `categoriesSlug:${category}`,
    hitsPerPage: 15,
  });
}

export async function fetchCollecionSection(collection) {
  const indexTopSellers = await searchSetup(topSellerIndex());
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return indexTopSellers.search('', {
    facetFilters: `collections:${collection}`,
    hitsPerPage: 15,
    attributesToRetrieve,
  });
}

export async function fetchBestOfSection() {
  const indexTopSellers = await searchSetup(topSellerIndex());
  const yearStart = Date.parse('2020-01-01T00:00:00.000Z') / 1000;
  const yearEnd = Date.parse('2020-12-31T00:00:00.000Z') / 1000;
  return indexTopSellers.search('', {
    filters: `flash_sale != 1 AND release_date > ${yearStart} AND release_date < ${yearEnd} AND type:game OR type:dlc`,
    hitsPerPage: 15,
  });
}

export async function fetchNewReleaseComingSoonSection() {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    hitsPerPage: 15,
    filters: `release_date > ${minusTwoMonths} OR release_date > ${today} AND price.${currencyCode} >=20 AND NOT display_type:bundle AND NOT display_type:book-bundle AND NOT display_type:comic-bundle`,
  });
}

export async function fetchFranchises() {
  let franchises = [];
  const index = await searchSetup(topSellerIndex());
  const { facets } = await index.search('', {
    attributesToRetrieve: ['franchises'],
    facets: ['franchises'],
    facetingAfterDistinct: true,
    maxValuesPerFacet: 1000,
  });

  if (!isEmpty(facets)) {
    franchises = Object.keys(facets.franchises).slice().sort();
  }

  return franchises;
}

export async function fetchMostWanted(hitsPerPage) {
  const indexMostWanted = await searchSetup(`${indexAlgoliaName}_most_wanted`);
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc']);
  return indexMostWanted.search('', {
    hitsPerPage: hitsPerPage || 11,
    attributesToRetrieve,
  }).then(mostWanted => mostWanted.hits);
}

export async function fetchProductBySlug(slug) {
  const index = await searchSetup(topSellerIndex());
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return index.search('', {
    attributesToRetrieve,
    filters: `(slug:"${slug}")`,
  }).then(result => result.hits[0]);
}

export async function fetchProductById(id) {
  const index = await searchSetup(topSellerIndex());
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);
  return index.search('', {
    attributesToRetrieve,
    filters: `(product_id:"${id}")`,
  }).then(result => result.hits[0]);
}

/**
 * concatenates a string from passed in product slugs that is suitable for
 * using as an algolia search filter paramater
 * @param  {Array.String} Slugs List product slugs
 * @return {String}      Product Slugs filter for algolia search
 */
const createSlugsFilterForAlgolia = (slugs) => {
  const headSlugString = slugs[0];
  const tailingSlugs = tail(slugs);
  let tailingSlugsString = '';

  if (tailingSlugs) {
    tailingSlugsString = tailingSlugs.map(slug => `OR slug:"${slug}"`).join(' ');
  }

  return `(slug:"${headSlugString}" ${tailingSlugsString})`;
};

export async function fetchBySlugs(slugLists, hitsPerPage) {
  if (!slugLists || !Array.isArray(slugLists) || !slugLists.length) return false;
  const indexName = topSellerIndex();

  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);

  const client = await searchSetup();
  const queries = [];

  slugLists.forEach((slugs) => {
    queries.push({
      indexName,
      params: {
        filters: createSlugsFilterForAlgolia(slugs),
        hitsPerPage: hitsPerPage || 25,
        attributesToRetrieve,
      },
    });
  });
  const results = await client.search(queries);
  return results.results.map((list, i) =>
    list.hits.sort((a, b) =>
      (slugLists[i].indexOf(a.slug) > slugLists[i].indexOf(b.slug) ? 1 : -1)));
}

export async function getBySlugsApi(slugs = [], sort, page = 1) {
  let result = { hits: [], count: 0 };
  if (isEmpty(slugs)) return result;

  let url = `/algolia/get-hits-by-slugs?slugs=${slugs.toString()}`;

  if (sort) {
    url += `&sort=${sort}`;
  }

  if (page) {
    url += `&page=${page}`;
  }

  try {
    const { response, data } = await apiGet([], url);
    if (response.ok) result = data;
  } catch (ex) { /* */ }

  return result;
}

/**
 * fetchEbookBundles fetches ebooks for the ebook landing page
 * @return {Array.Object}               results from algolia query
 */
export async function fetchEbookBundles() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/ebooks'));
  return data;
}

export async function fetchMixedBundles(indexName) {
  const { data: hits } = await apiGet([], apiAlgoliaSplitTest(`/algolia/bundles${indexName ? `/${indexName}` : ''}`));

  return hits || [];
}

export async function fetchSteamDeckGames(supportLevels, indexName, page = 0) {
  const index = await searchSetup(indexName ? `${indexAlgoliaName}_${indexName}` : topSellerIndex());

  return index.search('', {
    filters: supportLevels,
    hitsPerPage: 36,
    page,
  });
}

export async function fetchGameBundles(slug) {
  const indexName = topSellerIndex();
  const indexBundles = await searchSetup(indexName);
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['header']);

  let filters = 'display_type:bundle AND mystery_homepage != 1 AND mystery != 1';
  if (slug !== '') {
    filters = `display_type:bundle AND mystery_homepage != 1 AND mystery != 1 AND NOT slug:"${slug}"`;
  }

  return indexBundles.search('', {
    attributesToRetrieve,
    hitsPerPage: 4,
    filters,
  }).then(results => results.hits);
}


/**
 * fetchEbookBundleCarousel gets top selling ebooks carousel on the home / bundle page
 * @return {Array.Object} ebook hits;
 */
export async function fetchEbookBundleCarousel() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/get-ebook-section'));
  return data;
}


export async function fetchMoreThanGames() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/get-more-than-games-section'));
  return data;
}

export async function fetchOnSale() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/onsaleresults'));
  return data;
}

export async function fetchHomeHits() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/home'));
  return data;
}

export async function fetchFeaturedCarousel() {
  const newUser = GeneralUtils.newUser();
  const { data } = await apiGet([], `/algolia/home-carousel/${window.locale}`);

  if (newUser) return data;

  // Remove giveaway slide for returning users
  return data.filter(slide => slide.type !== 'product' || (slide.type === 'product' && slide.hit && !slide.hit.giveaway));
}

export async function fetchFeaturedFantasyVerseCarousel() {
  const { data } = await apiGet([], `/algolia/fantasyverse-carousel/${window.locale}`);
  return data;
}

export async function fetchFantasyVerse() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/fantasyverse'));
  return data;
}

export async function fetchFeaturedCarouselPreview(startDate) {
  const { response, data } = await apiGet([], `/algolia/home-carousel-preview/${window.locale}/startdate=${startDate}`);
  if (!response.ok) {
    history.push('/');
    // eslint-disable-next-line no-alert
    window.alert('Unathorized: Preview feature requires logging into admin account');
    return [];
  }
  return data;
}

export async function fetchGiftGuide() {
  const { data } = await apiGet([], apiAlgoliaSplitTest('/algolia/gift-guide'));
  return data;
}

/**
 * fetchEbooks used on top sellers page to fetch ebooks page when filtering top sellers by type
 * @param  {Number} hitsPerPage   hits to display
 * @param  {Number} [page=0]      which page
 * @return {Object}               results from algolia query
 */
export async function fetchEbooks(hitsPerPage, page = 0) {
  const indexName = topSellerIndex();
  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);

  const index = await searchSetup(indexName);
  return index.search('', {
    hitsPerPage: hitsPerPage || 36,
    filters: 'display_type:book-bundle OR display_type:elearning-bundle OR display_type:elearning OR display_type:book',
    page,
    attributesToRetrieve,
  }).then(results => results);
}

export async function fetchNotFoundTopSellers(hitsPerPage, page = 0) {
  const indexName = topSellerIndex();
  const index = await searchSetup(indexName);
  return index.search('', {
    hitsPerPage: hitsPerPage || 36,
    filters: 'type:game OR type:dlc OR display_type:bundle',
    page,
  }).then(results => results.hits);
}

export async function fetchAllGames(hitsPerPage, page = 0) {
  const indexName = `${indexAlgoliaName}_unlimited`;
  const index = await searchSetup(indexName);
  return index.search('', {
    hitsPerPage: hitsPerPage || 36,
    filters: 'display_type:game',
    page,
  });
}

export async function fetchAllDLC(hitsPerPage, page = 0) {
  const indexName = `${indexAlgoliaName}_unlimited`;
  const index = await searchSetup(indexName);
  return index.search('', {
    hitsPerPage: hitsPerPage || 36,
    filters: 'display_type:dlc',
    page,
  });
}

export async function fetchFacetFeatureQuery(facetFilter, facets, page = 0) {
  const index = await searchSetup(topSellerIndex());

  const facetList = uniq(facets.map(facet => facet.field));

  const facetFilters = Object.values(groupBy(facets.filter(f => f.selected), 'segment'))
    .map(segment => segment.map(facet => `${facet.field}:${facet.key}`));

  return index.search('', {
    hitsPerPage: 36,
    page,
    facetFilters: [facetFilter, ...facetFilters],
    facets: facetList,
    facetingAfterDistinct: true,
  });
}

export async function fetchProductFinderFacets(selectedFacets) {
  const index = await searchSetup(topSellerIndex());
  let filters = '';

  Object.keys(selectedFacets).forEach((facet) => {
    if (!isEmpty(selectedFacets[facet])) {
      filters += `${filters.length ? ' AND ' : ''}${selectedFacets[facet].map(t => `${facet}Slug:${encodeSlug(t)}`).join(' AND ')}`;
    }
  });

  return index.search('', {
    hitsPerPage: 0,
    facets: ['genresSlug', 'playstylesSlug', 'themesSlug'],
    filters,
  }).then(hits => hits);
}

export async function fetchComboQuery(comboRules, sort, page, facets) {
  const searchIndex = sort ? `${indexAlgoliaName}_${sort}` : topSellerIndex();

  const index = await searchSetup(searchIndex);

  let filters = '';

  Object.keys(comboRules).forEach((facet) => {
    if (comboRules[facet] && comboRules[facet].or && comboRules[facet].or.length) {
      filters += `${filters.length ? ' AND ' : ''}(${comboRules[facet].or.map(t => (facet === 'type' ? `type:${t}` : `${facet}Slug:${encodeSlug(t)}`)).join(' OR ')})`;
    }
    if (comboRules[facet] && comboRules[facet].and && comboRules[facet].and.length) {
      filters += `${filters.length ? ' AND ' : ''}(${comboRules[facet].and.map(t => (facet === 'type' ? `type:${t}` : `${facet}Slug:${encodeSlug(t)}`)).join(' AND ')})`;
    }
  });

  if (sort === 'price_asc') filters += ' AND presale != 1';

  return index.search('', {
    hitsPerPage: 36,
    page,
    facets,
    filters,
  });
}

export async function fetchHallOfFame({ bestOfYear, hitsPerPage }) {
  const index = await searchSetup(`${indexAlgoliaName}_metacritic`);

  const { locale } = window;
  const attributesToRetrieve = globalAttributesToRetrieve.concat(['sdesc', `sdescs.${locale}`]);

  const startDate = (new Date(`${bestOfYear}-01-01T00:00:00.000Z`).getTime() / 1000);
  const endDate = (new Date(`${bestOfYear + 1}-01-01T00:00:00.000Z`).getTime() / 1000);

  return index.search('', {
    attributesToRetrieve,
    hitsPerPage,
    filters: `release_date > ${startDate} AND release_date < ${endDate} AND metacritic_score > 80 AND presale != 1 AND type:game OR type:dlc`,
  }).then(results => results.hits);
}

export async function fetchGiftCards(page, hitsPerPage) {
  const indexGiftCards = await searchSetup(topSellerIndex());
  return indexGiftCards.search('', {
    page: sanitizePageNumber(page),
    hitsPerPage: hitsPerPage || 36,
    filters: 'display_type:gift-card',
  }).then(hits => hits);
}

export async function fetchStandardUpsellDiscounts() {
  const indexTopSellers = await searchSetup(topSellerIndex());
  return indexTopSellers.search('', {
    hitsPerPage: 15,
    filters: 'upsell = 1',
  }).then(results => results.hits);
}

/**
 * Fetches non-mystery upsell fallback array
 * @return {Object}             algolia results
 */
export async function fetchStandardUpsellsFallback(purchaseHistoryNames) {
  const index = await searchSetup(topSellerIndex());

  const priceObject = {
    CAD: 300,
    USD: 300,
    EUR: 300,
    GBP: 300,
    AUD: 300,
    RUB: 3338,
    JPY: 5500,
  };

  let filters = `type:game AND discount_percent > 50 AND metacritic_score > 75 AND price.${currencyCode} < ${priceObject[currencyCode] / 100}`;

  if (!isEmpty(purchaseHistoryNames)) {
    filters = `${createNamesToExcludeFilter(purchaseHistoryNames)} AND type:game AND discount_percent > 50 AND metacritic_score > 75 AND price.${currencyCode} < ${priceObject[currencyCode] / 100}`;
  }

  return index.search('', {
    filters,
  }).then(results => results.hits);
}

export async function fetchMostGifted() {
  const indexBundles = await searchSetup(topSellerIndex());
  const slugFilter = createSlugsFilterForAlgolia(mostGiftedSlugs);

  return indexBundles.search('', {
    filters: `${slugFilter} AND discount_percent > 0`,
    hitsPerPage: 50,
  }).then(results => results);
}

export async function fetchMostGiftedSection() {
  const indexBundles = await searchSetup(topSellerIndex());
  const slugFilter = createSlugsFilterForAlgolia(mostGiftedSlugs);

  return indexBundles.search('', {
    filters: `${slugFilter} AND discount_percent > 0`,
    hitsPerPage: 15,
  }).then(results => results.hits);
}


export async function fetchTopSellingPickAndMixes() {
  const indexBundles = await searchSetup(topSellerIndex());

  return indexBundles.search('', {
    filters: 'type:pick-and-mix',
    hitsPerPage: 100,
  }).then(results => results.hits);
}

export async function fetchDlcBySlug(slug) {
  const index = await searchSetup(topSellerIndex());
  return index.search('', {
    hitsPerPage: 100,
    filters: `(parent_slug:"${slug}") AND type:dlc`,
  }).then(result => result.hits);
}
